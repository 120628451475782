import { isDefinedAndNotVoid } from "src/helpers/utils";

function filterAuthorizationRoles(roles) {
    return !isDefinedAndNotVoid(roles) ? getDefaultRole() : roles.length === 1 ? roles[0] : roles.find(role => role.includes('ADMIN'));
}

function filterRoles(roles) {
    const rolesArray = !Array.isArray(roles) ? Object.keys(roles).map(i => roles[i]) : roles;
    return  rolesArray.length === 1 ? rolesArray[0] : 
            rolesArray.includes("ROLE_SELLER") && rolesArray.includes("ROLE_DELIVERER") ? "ROLE_PICKER" : 
            rolesArray.filter(role => role !== getDefaultRole())[0];
}

function isRelaypoint(roles) {
    return (Array.isArray(roles) && roles.includes("ROLE_RELAYPOINT")) || roles === "ROLE_RELAYPOINT";
}

function hasPrivileges(user) {
    return hasAdminPrivileges(user) || hasAdminAccess(user);
}

function hasAdminAccess(user) {
    const adminAccessRoles = [ "ROLE_TEAM", "ROLE_MANAGER", "ROLE_STAFF_MANAGER", "ROLE_OFFICE_MANAGER", "ROLE_ADMIN", "ROLE_SUPER_ADMIN"];
    return adminAccessRoles.includes(user.roles);
}

function isSeller(user) {
    return user.roles === "ROLE_SELLER";
}

function isTeamMember(user) {
    return user.roles === "ROLE_TEAM";
}

function isMaker(user) {
    return isMakerManager(user) || user.roles === "ROLE_MAKER";
}

function isMakerManager(user) {
    return isSellerMaker(user) || user.roles === "ROLE_MAKER_MANAGER";
}

function isSellerMaker(user) {
    return user.roles === "ROLE_SELLER_MAKER";
}

function isDeliverer(user) {
    return user.roles === "ROLE_DELIVERER";
}

function isPicker(user) {
    return user.roles === "ROLE_PICKER";
}

function isSupervisor(user) {
    return user.roles === "ROLE_SUPERVISOR";
}

function isStoreManager(user) {
    return user.roles === "ROLE_STORE_MANAGER";
}

function hasAdminPrivileges(user) {
    return user.roles.includes('ADMIN');
}

function hasAllPrivileges(user) {
    return user.roles.includes('SUPER_ADMIN');
}

function isManager(user) {
    return user.roles.includes('MANAGER');
}

function isStaffManager(user) {
    return user.roles.includes('STAFF_MANAGER');
}

function isOfficeManager(user) {
    return user.roles.includes('OFFICE_MANAGER');
}

function getDefaultRole() {
    return "ROLE_USER";
}

function isProfesional(roles) {
    return roles.find(r => ["ROLE_VIP", "ROLE_GRANDS_COMPTES", "ROLE_CHR", "ROLE_PROFESSIONNELS"].includes(r)) !== undefined;
}

export default {
    filterRoles,
    getDefaultRole,
    hasPrivileges,
    hasAdminAccess,
    hasAdminPrivileges,
    hasAllPrivileges,
    filterAuthorizationRoles,
    isSellerMaker,
    isMakerManager,
    isTeamMember,
    isSeller,
    isMaker,
    isDeliverer,
    isRelaypoint,
    isPicker,
    isSupervisor,
    isStoreManager,
    isProfesional,
    isStaffManager,
    isOfficeManager,
    isManager
}