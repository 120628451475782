import React from 'react';

export default React.createContext({
    updatedTasks: [],
    setUpdatedTasks: (value) => {},
    updatedFulfilments: [], 
    setUpdatedFulfilments: (value) => {},
    updatedOrders: [],
    setUpdatedOrders: (value) => {},
    updatedProducts: [],
    setUpdatedProducts: (value) => {},
    updatedUsers: [], 
    setUpdatedUsers: (value) => {},
    updatedCategories: [],
    setUpdatedCategories: (value) => {},
    updatedProvisions: [],
    setUpdatedProvisions: (value) => {},
    updatedContainers: [],
    setUpdatedContainers: (value) => {},
    updatedMessages: [],
    setUpdatedMessages: (value) => {},
    updatedProductions: [],
    setUpdatedProductions: (value) => {},
});