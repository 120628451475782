import axios from 'axios';
import api from 'src/config/api';
import { isDefined } from 'src/helpers/utils';

function register(user) {
    const { name, email, password } = user;
    return api.post("/users", { name, email, password });
}

function findAll() {
    return api
        .get(`/users?pagination=false`)
        .then(response => response.data['hydra:member']);
}

function findAllPaginated(page = 1, items = 30) {
    return api
        .get(`/users?pagination=true&order[name]=asc&page=${ page }&itemsPerPage=${ items }`)
        .then(response => response.data);
}

function findWord(word, page = 1, items = 30) {
    return api
        .get(`/users?name=${ word }&order[name]=asc&pagination=true&page=${ page }&itemsPerPage=${ items }`)
        .then(response => response.data);
}

function deleteUser(id) {
    return api
        .delete('/users/' + id);
}

function find(id) {
    return api.get('/users/' + id)
                .then(response => response.data);
}

function update(id, user) {
    return api.put('/users/' + id, user);
}

function create(user) {
    return api.post('/users', user);
}

function findUser(search) {
    return api
            .get('/users?name=' + search)
            .then(response => response.data['hydra:member']);
}

function findDeliverers() {
    return api
            .get('/users?roles=DELIVERER')
            .then(response => response.data['hydra:member']);
}

function getAccountingId(order) {
    return isDefined(order.user) && isDefined(order.user.accountingId) ? new Promise((resolve, reject) => resolve(order.user.accountingId)) :
         api.post('/accounting/user/' + order.id)
            .then(response => response.data)
}

function banUser(user) {
    return api
            .post('/ban/' + user.id)
            .then(response => response.data);
}

function importUsers(file) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post(api.API_DOMAIN + "/upload/users", formData, {headers: {'Content-type': 'multipart/form-data'}, withCredentials: true});
}

function updatePassword(user, password) {
    return axios.patch(api.API_DOMAIN + '/reset-user-password', {username: user.email, password})
                .then(response => response.data)
                .catch(error => ({status: 'failed'}))
}

function getTeamMembers() {
    return api.get('/users?managers=true&pagination=false')
              .then(response => response.data['hydra:member']);
}

export default {
    register,
    findAll,
    findAllPaginated,
    findWord,
    delete: deleteUser,
    find, 
    update, 
    create,
    findUser,
    banUser,
    importUsers,
    findDeliverers,
    getAccountingId,
    updatePassword,
    getTeamMembers
}