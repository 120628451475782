import axios from 'axios';

const API_PREFIX = "";      // "" || "/api"
const DEV_API_DOMAIN = API_PREFIX.includes("api") ? "http://localhost:8000" + API_PREFIX : "https://localhost" + API_PREFIX;
const DEV_MERCURE_DOMAIN = API_PREFIX.includes("api") ? "http://localhost:3000" : "https://localhost";
const API_LOGIN_ENDPOINT = API_PREFIX.includes("api") ? "/login_check" : "/authentication_token";

const ENV = (!process.env.NODE_ENV || process.env.NODE_ENV === "development") ? "development" : "production" ;
const API_DOMAIN = ENV === "development" ? DEV_API_DOMAIN : "https://api.createam.re" + API_PREFIX;
const MERCURE_DOMAIN = ENV === "development" ? DEV_MERCURE_DOMAIN : "https://api.createam.re";
const CLIENT_DOMAIN = ENV === "development" ? "http://localhost:3001" : "https://createam.re";
const ATTACHMENT_FOLDER = API_DOMAIN + "/uploads/attachments/";

function get(route) {
    return axios.get(API_DOMAIN + route);
}

function deleteEntity(route) {
    return axios.delete(API_DOMAIN + route);
}

function post(route, entity) {
    return axios.post(API_DOMAIN + route, entity);
}

function put(route, entity) {
    return axios.put(API_DOMAIN + route, entity);
}

function patch(route, entity) {
    return axios.patch(API_DOMAIN + route, entity, { headers: {'Content-type': 'application/merge-patch+json'} });
}

export default {
    API_PREFIX,
    API_DOMAIN,
    CLIENT_DOMAIN,
    MERCURE_DOMAIN,
    API_LOGIN_ENDPOINT,
    ATTACHMENT_FOLDER,
    get,
    post,
    put,
    patch,
    delete: deleteEntity
}